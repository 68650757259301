import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

const StyledCard = styled.div`
    text-align: center;
    font-size: 19px;
    padding: 10px 50px;
    border-right: 1px solid orange;
    max-width: 300px;
`

const StyledCards = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    justify-content: center;
    margin: 50px 0px;

    @media (max-width: 1200px) {
        display: none;
    }
`

const Steps = () =>{
    const intl = useIntl()

    const data = useStaticQuery(graphql`
	    query {
			one: file(relativePath: { eq: "1.png" }) {
				childImageSharp {
                    fixed {
                        ...GatsbyImageSharpFixed
                    }
				}
            },
			two: file(relativePath: { eq: "2.png" }) {
				childImageSharp {
                    fixed {
                        ...GatsbyImageSharpFixed
                    }
				}
            },
			three: file(relativePath: { eq: "3.png" }) {
				childImageSharp {
                    fixed {
                        ...GatsbyImageSharpFixed
                    }
				}
            },
        },
    `)

    return (
        <StyledCards>
            <StyledCard>
                <Img fixed={data.one.childImageSharp.fixed} />
                <div style={{paddingTop: '20px'}}>
                    {intl.formatMessage({ id: "tag_tracker.steps.one" })}
                </div>
            </StyledCard>
            <StyledCard>
                <Img fixed={data.two.childImageSharp.fixed} />
                <div style={{paddingTop: '20px'}}>
                    {intl.formatMessage({ id: "tag_tracker.steps.two" })}
                </div>
            </StyledCard>
            <StyledCard style={{borderRight: "none"}}>
                <Img fixed={data.three.childImageSharp.fixed} />
                <div style={{paddingTop: '20px'}}>
                    {intl.formatMessage({ id: "tag_tracker.steps.three" })}
                </div>
            </StyledCard>
        </StyledCards>
    )
}

export default Steps