import React, { useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { ORANGE, GRAY, CLOUD_URL } from "../../../constants"

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 20px 10px 75px;

    @media(max-width: 1200px) {
        margin: 30px;
    }
`

const StyledInput = styled.input`
    margin: 10px 0;
    padding: 10px;
    font-family: Roboto;
    font-size: 15px;

    :focus {
        outline: 1px solid ${ORANGE};
        box-shadow: none;
    }
`

const StyledText = styled.textarea`
    margin: 10px 0;
    padding: 10px;
    font-family: Roboto;
    font-size: 15px;
    resize: none;

    :focus {
        outline: 1px solid ${ORANGE};
        box-shadow: none;
    }
`

const StyledButton = styled.button`
    border-radius: 5px;
    margin-top: 75px;
    padding: 10px;
    border: 0px;
    background-color: ${ORANGE};
    color: #FFF;
    font-size: 22px;
    border: 1px solid ${ORANGE};
    cursor: pointer;
    text-transform: uppercase;
`

const StyledMessage = styled.div`
    visibility: visible;
    min-width: 275px;
    margin-left: -130px;
    background-color: ${GRAY};
    color: white;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: -75px;
    
    -webkit-animation: ${props => props.show ? "fade 4s" : ""};
    animation: ${props => props.show ? "fade 4s" : ""};

    @-webkit-keyframes fade {
        0% {bottom: 150px; opacity: 1;}
        50% {bottom: 150px; opacity: 1;}
        100% {bottom: 150px; opacity: 0;}
    }

    @keyframes fade {
        0% {bottom: 150px; opacity: 1;}
        50% {bottom: 150px; opacity: 1;}
        100% {bottom: 150px; opacity: 0;}
    }
`

const Form = () => {
    const intl = useIntl()

    const [data, setData] = useState({name: "", email: "", role: "", message: ""})
    const [responseMessage, setResponseMessage] = useState("")
    const [showMessage, setShowMessage] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault()

        let xhr = new XMLHttpRequest()

        xhr.open("POST", CLOUD_URL)
        xhr.send(
            JSON.stringify({
                name: data.name,
                email: data.email,
                message: data.message,
                role: data.role,
                type: "Landing Page"
            })
        )

        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                console.log(xhr)
                if (xhr.status === 200) {
                    setShowMessage(true)
                    setResponseMessage(intl.formatMessage({id:"raccoon.contact.snackbar.success"}))
                    document.getElementById("form").reset()
                } else {
                    setShowMessage(true)
                    setResponseMessage(intl.formatMessage({id:"raccoon.contact.snackbar.fail"}))
                }
            }
        }

        setShowMessage(false)
    }

    const handleInputChange = e => {
        setShowMessage(false)
        const {name, value} = e.target
        setData({...data, [name]: value})
    }

    return(
        <StyledForm id="form" onSubmit={event => handleSubmit(event)}>
            <StyledInput
                type="text"
                name="name"
                label="name"
                placeholder={intl.formatMessage({id:"raccoon.contact.name"})}
                required
                onChange={handleInputChange} />
            <StyledInput
                type="email"
                name="email"                
                placeholder={intl.formatMessage({id:"raccoon.contact.email"})}
                required
                onChange={handleInputChange} />
            <StyledInput
                type="text"
                name="role"
                label="role"                
                placeholder={intl.formatMessage({id:"raccoon.contact.role"})}
                required
                onChange={handleInputChange} />
            <StyledText
                name="message"
                rows="5"
                placeholder={intl.formatMessage({id:"raccoon.contact.message"})}
                required
                onChange={handleInputChange} />
            <StyledButton>
                {intl.formatMessage({id:"raccoon.contact.send"})}
            </StyledButton>
            <StyledMessage show={showMessage}>
                {responseMessage}
            </StyledMessage>
        </StyledForm>
    )
}

export default Form
