import React from "react"
import Helmet from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"


const SEO = () => {
	const intl = useIntl()
	const lang = intl.locale

	return (
		<Helmet
			htmlAttributes={{lang}}
			title={`${intl.formatMessage({id:"site.title"})} - ${intl.formatMessage({id:"site.subtitle"})}`}
			titleTemplate={`${intl.formatMessage({id:"site.title"})} - ${intl.formatMessage({id:"site.subtitle"})}`}
			meta={[
				{
					name: `description`,
					content: intl.formatMessage({id:"site.description"}),
				}, {
					property: `og:title`,
					content: intl.formatMessage({id:"site.title"}),
				}, {
					property: `og:description`,
					content: intl.formatMessage({id:"site.description"}),
				}, {
					property: `og:type`,
					content: `website`,
				}, {
					name: `twitter:card`,
					content: `summary`,
				}, {
					name: `twitter:creator`,
					content: intl.formatMessage({id:"site.author"}),
				}, {
					name: `twitter:title`,
					content: intl.formatMessage({id:"site.title"}),
				}, {
					name: `twitter:description`,
					content: intl.formatMessage({id:"site.description"}),
				}
			].concat([])}
		>
			<link rel="canonical" href="https://tag-tracker.raccoon.ag/" />
			<link rel="alternate" hreflang="x-default" href="https://tag-tracker.raccoon.ag/" />
			<link rel="alternate" hreflang="pt" href="https://tag-tracker.raccoon.ag/pt" />
			<meta name="google-site-verification" content="1GCnzUbOHnysD2O68ZicZi7RW10cmi7soNcS0477e4M" />
		</Helmet>
	)
}

export default SEO
