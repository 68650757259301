import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

import { RACCOON_URL, ORANGE, DARKORANGE } from "../constants"
import Contact from "./Contact"

const StyledRaccoon = styled.div`
    padding: 150px 100px;
	display: flex;

	@media (max-width: 1200px) {
		margin: 30px 0px 0px;
		padding: 0;
		flex-direction: column;
		justify-content: center;
    }
`

const StyledH2 = styled.h2`
    font-size: 35px;
    text-align: center;
`

const StyledParagraph = styled.p`
    margin: 30px 100px;
    text-align: center;
    line-height: 22px;
    font-size: 17px;
`

const StyledImgDiv = styled.div`
    text-align: center;

    > p {
        font-size: 15px;
        max-width: 100px;
		margin: 30px;
    }
`

const StyledServices = styled.div`
    display: flex;
    justify-content: center;

    flex-wrap: wrap;
`

const StyledService = styled.div`
    display: flex;
`

const StyledButtonDiv = styled.div`
    padding-top: 50px;
	text-align: center;
`


const StyledDivRaccoon = styled.div`
	width: 80%;
	border-right: 1px solid ${ORANGE};
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media(max-width: 1200px) {
		border-right: 0px;
		width: 100%;
		margin-bottom: 80px;
	}
`

const StyledButtonA = styled.a`
    border-radius: 5px;
    padding: 11px 20px;
    border: 0px;
    background-color: ${ORANGE};
    color: #FFF;
    font-size: 22px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;

    :hover {
        background-color: ${DARKORANGE};
    }
`



const Raccoon = () => {
	const intl = useIntl()
	
    const data = useStaticQuery(graphql`
	    query {
			web: file(relativePath: { eq: "web-analytics.png" }) {
				childImageSharp {
					fixed(width: 50, height: 50) {
						...GatsbyImageSharpFixed
					}
				}
			},
			market: file(relativePath: { eq: "market.png" }) {
				childImageSharp {
					fixed(width: 50, height: 50) {
						...GatsbyImageSharpFixed
					}
				}
			},
			media: file(relativePath: { eq: "media.png" }) {
				childImageSharp {
					fixed(width: 50, height: 50) {
						...GatsbyImageSharpFixed
					}
				}
			},
			attribuition: file(relativePath: { eq: "attribuition.png" }) {
				childImageSharp {
					fixed(width: 50, height: 50) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)

    return(
		<StyledRaccoon id="raccoon">
			<StyledDivRaccoon>
			<StyledH2>                        
				{intl.formatMessage({ id: "raccoon.h2" })}
			</StyledH2>
			<StyledParagraph>
				{intl.formatMessage({ id: "raccoon.paragraph" })}
			</StyledParagraph>
			<StyledServices>
				<StyledService>
					<StyledImgDiv>
						<Img fixed={data.web.childImageSharp.fixed}  />
						<StyledParagraph>
							{intl.formatMessage({ id: "raccoon.web" })}
						</StyledParagraph>
					</StyledImgDiv>
				</StyledService>
				<StyledService>
					<StyledImgDiv>
						<Img fixed={data.market.childImageSharp.fixed}  />
						<StyledParagraph>
							{intl.formatMessage({ id: "raccoon.market" })}
						</StyledParagraph>
					</StyledImgDiv>
				</StyledService>
				<StyledService>
					<StyledImgDiv>
						<Img fixed={data.media.childImageSharp.fixed}  />
						<StyledParagraph>
							{intl.formatMessage({ id: "raccoon.media" })}
						</StyledParagraph>
					</StyledImgDiv>
				</StyledService>
				<StyledService>
					<StyledImgDiv>
						<Img fixed={data.attribuition.childImageSharp.fixed}  />
						<StyledParagraph>
							{intl.formatMessage({ id: "raccoon.attribuition" })}
						</StyledParagraph>
					</StyledImgDiv>
				</StyledService>
			</StyledServices>
            <StyledButtonDiv>
				<StyledButtonA href={RACCOON_URL} target="_blank">
					{intl.formatMessage({ id: "raccoon.website" })}
				</StyledButtonA>
            </StyledButtonDiv>
			</StyledDivRaccoon>
			<Contact />
		</StyledRaccoon>
	)
}

export default Raccoon