import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Extension from "./Extension"
import Tab from "./Functionality"

const StyledTagTracker = styled.div`
    padding: 80px 150px 0px;

    @media (max-width: 800px) {
        padding: 50px;
    }
`

const StyledH2 = styled.h2`
    font-size: 35px;
    text-align: center;
    margin-top: 20px;
`

const StyledParagraph = styled.p`
    margin: 50px 0px;
    line-height: 25px;
    font-size: 17px;
    text-align: center;
`

const TagTracker = () => {
    const intl = useIntl()

    return(
        <StyledTagTracker id="tag-tracker">
            <StyledH2> 
                {intl.formatMessage({ id: "tag_tracker.h2" })}
            </StyledH2>
            <StyledParagraph>
                {intl.formatMessage({ id: "tag_tracker.paragraph" })}
            </StyledParagraph>
            <Tab />
            <Extension />
        </StyledTagTracker>
    )
}

export default TagTracker