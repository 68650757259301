import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

const StyledTopics = styled.div`
    padding: 80px 150px 0px;

    @media (max-width: 800px) {
        padding: 30px 50px 30px;
    }
`

const StyledH3 = styled.h3`
    font-size: 24px;
    font-weight: bold;
`

const StyledCard = styled.div`
    margin: 40px;
    width: 300px;
    text-align: center;
    font-size: 17px;

    @media (max-width: 800px) {
        width: 200px;
    }
`

const StyledCards = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;

    @media (max-width: 800px) {
        border-bottom: groove;
        padding-bottom: 50px;
    }
`

const StyledParagraph = styled.p`
    line-height: 22px;
    margin-top: 10px;

    b {
        font-weight: 600;
        color: #F9932C;
    }
`

const Topics = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
	    query {
			flux: file(relativePath: { eq: "flux.png" }) {
				childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
				}
			},
			insights: file(relativePath: { eq: "bulb.png" }) {
				childImageSharp {
                    fixed(width: 40, height: 40) {
                        ...GatsbyImageSharpFixed
                    }
				}
			},
			like: file(relativePath: { eq: "thumbs.png" }) {
				childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
				}
			},
			magnify: file(relativePath: { eq: "magnify.png" }) {
				childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
				}
			},
			question: file(relativePath: { eq: "question.png" }) {
				childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
				}
			},
			target: file(relativePath: { eq: "target.png" }) {
				childImageSharp {
                    fixed(width: 35, height: 35) {
                        ...GatsbyImageSharpFixed
                    }
				}
			}
        }
	`)

    return (
        <StyledTopics>
            <StyledH3>
                    {intl.formatMessage({ id: "importance.topics.h3" })}
            </StyledH3>
            <StyledCards>
                <StyledCard>
                    <Img fixed={data.flux.childImageSharp.fixed} />
                    <StyledParagraph>
                        <b style={{fontWeght: "600px"}}>
                            {intl.formatMessage({ id: "importance.topics.flux.main" })}
                        </b>
                        {intl.formatMessage({ id: "importance.topics.flux.text" })}
                    </StyledParagraph>
                </StyledCard>
                <StyledCard>
                    <Img fixed={data.insights.childImageSharp.fixed} />
                    <StyledParagraph>
                        {intl.formatMessage({ id: "importance.topics.insights.first_text" })}
                        <b style={{fontWeght: "600px"}}>
                            {intl.formatMessage({ id: "importance.topics.insights.main" })}
                        </b>
                        {intl.formatMessage({ id: "importance.topics.insights.second_text" })}
                    </StyledParagraph>
                </StyledCard>
                <StyledCard>
                    <Img fixed={data.like.childImageSharp.fixed} />
                    <StyledParagraph>
                        <b style={{fontWeght: "600px"}}>
                            {intl.formatMessage({ id: "importance.topics.like.main" })}
                        </b>
                        {intl.formatMessage({ id: "importance.topics.like.text" })}
                    </StyledParagraph>
                </StyledCard>
                <StyledCard>
                    <Img fixed={data.magnify.childImageSharp.fixed} />
                    <StyledParagraph>
                        {intl.formatMessage({ id: "importance.topics.magnify.first_text" })}
                        <b style={{fontWeght: "600px"}}>
                            {intl.formatMessage({ id: "importance.topics.magnify.main" })}
                        </b>
                        {intl.formatMessage({ id: "importance.topics.magnify.second_text" })}
                    </StyledParagraph>
                </StyledCard>
                <StyledCard>
                    <Img fixed={data.question.childImageSharp.fixed} />
                    <StyledParagraph>
                        <b style={{fontWeght: "600px"}}>
                            {intl.formatMessage({ id: "importance.topics.question.main" })}
                        </b>
                        {intl.formatMessage({ id: "importance.topics.question.text" })}
                    </StyledParagraph>
                </StyledCard>
                <StyledCard>
                    <Img fixed={data.target.childImageSharp.fixed}  />
                    <StyledParagraph>
                        <b style={{fontWeght: "600px"}}>
                            {intl.formatMessage({ id: "importance.topics.target.main" })}
                        </b>
                        {intl.formatMessage({ id: "importance.topics.target.text" })}
                    </StyledParagraph>
                </StyledCard>
            </StyledCards>
        </StyledTopics>
    )
}

export default Topics