import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Form from "./Form"

import { GRAY } from "../../constants"

const StyledContact = styled.div`
    color: ${GRAY};
    width: 40%;
    margin-left: 50px;

    @media(max-width: 1200px) {
        width: 100%;
        margin: 0;
    }
`

const StyledParagraph = styled.p`
    font-size: 30px;
    padding: 0px 20px;

    @media(max-width: 1200px) {
        margin-top: 50px;
        text-align: center;
        padding: 0px 20px;
    }
`

const Contact = () => {
    const intl = useIntl()
    
    return(
        <StyledContact id="contact">
            <StyledParagraph>
			    {intl.formatMessage({ id: "raccoon.contact.paragraph" })}
            </StyledParagraph>
            <Form />
        </StyledContact>
    )
}

export default Contact