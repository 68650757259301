import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { TAG_TRACKER_URL, ORANGE } from "../../constants"

import Steps from "./Steps"

const StyledExtension = styled.div`
    margin: 50px 100px 50px;
    padding: 50px 100px;
    border-radius: 10px;
    text-align: center;

    @media (max-width: 1280px) {
        margin: 0px 0px 50px;
        padding: 0px;
        border: 0px;
    }
`

const StyledParagraph = styled.p`
    padding: 5px;
    line-height: 20px;
    font-size: 20px;
`

const StyledButtonDiv = styled.div`
    padding-top: 50px
`

const StyledA = styled.a`
    border-radius: 5px;
    padding: 15px 20px;
    background-color: ${ORANGE};
    color: #FFF;
    font-size: 22px;
    cursor: pointer;
    text-decoration: none;
`

const Extension = () => {
    const intl = useIntl()

    return (
        <StyledExtension>
            <StyledParagraph>
                {intl.formatMessage({ id: "tag_tracker.extension.main_paragraph" })}
            </StyledParagraph>
            <StyledParagraph>
                {intl.formatMessage({ id: "tag_tracker.extension.secundary_paragraph" })}
            </StyledParagraph>
            <Steps />
            <StyledButtonDiv>
                <StyledA href={TAG_TRACKER_URL} target="_blank">
                    {intl.formatMessage({ id: "header.install" })}
                </StyledA>
            </StyledButtonDiv>
        </StyledExtension>
    )
}

export default Extension
