import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useIntl, Link } from "gatsby-plugin-intl"

import { TAG_TRACKER_URL, ORANGE, DARKORANGE, GRAY } from "../constants"

const StyledHeader = styled.div`
    border-bottom: 1px solid ${ORANGE};
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0px 10px;
    background-color: #FFF;
    position: fixed;
    z-index: 1;
    width: 100%;
`

const StyledLogo = styled.div`
    display: flex;
    justify-content: left;

    @media (max-width: 800px) {
        width: 100%;
        justify-content: center;
    }
`

const StyledTab = styled.div`
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    text-align: center;

    @media (max-width: 800px) {
        display: none;
    }
`

const StyledOptions = styled.button`
    padding: 10px 20px;
    border: 0px;
    background-color: transparent;
    font-size: 17px;
    cursor: pointer;
    outline: none;
`

const StyledParagraph = styled.div`
    padding: 10px;
`

const StyledA = styled.a`
    border-radius: 5px;
    padding: 11px 20px;
    border: 0px;
    background-color: ${ORANGE};
    color: #FFF;
    font-size: 17px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;

    :hover {
        background-color: ${DARKORANGE};
    }
`


const Header = () => {
    const intl = useIntl()
    const lang = intl.locale

    const data = useStaticQuery(graphql`
	    query {
	      logo: file(relativePath: { eq: "logo.png" }) {
	        childImageSharp {
	          fixed(width: 22, height: 35) {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }, 
	      usa: file(relativePath: { eq: "usa.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }, 
	      brazil: file(relativePath: { eq: "brazil.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }
	    }
    `)

    const color = lang === "en" ? {pt: GRAY, en: ORANGE}: {pt: ORANGE, en: GRAY}
    
    return (
        <StyledHeader id="header">
            <StyledOptions onClick={() => scrollTo('#start')}>
                <StyledLogo>
                    <Img fixed={data.logo.childImageSharp.fixed} />
                    <StyledParagraph> Tag Tracker </StyledParagraph>
                </StyledLogo>
            </StyledOptions>
            <StyledTab>
                    <StyledOptions onClick={() => scrollTo('#what')}> 
                        {intl.formatMessage({ id: "header.tags" })}
                    </StyledOptions>
                    <StyledOptions onClick={() => scrollTo('#tag-tracker')}>
                        {intl.formatMessage({ id: "header.tag_tracker" })}
                    </StyledOptions>
                    <StyledOptions onClick={() => scrollTo('#raccoon')}>
                        {intl.formatMessage({ id: "header.raccoon" })}
                    </StyledOptions>
                    <StyledA href={TAG_TRACKER_URL} target="_blank">
                        {intl.formatMessage({ id: "header.install" })}
                    </StyledA>
                </StyledTab>
                <StyledOptions style={{display: "flex"}}>
                    <Link style={{display: "flex", paddingRight: "15px", borderRight: `1px solid ${GRAY}`, textDecoration: "none", color: color.en}} to="/../">
                        <Img fixed={data.usa.childImageSharp.fixed} />
                        <p style={{paddingLeft: "10px"}}> EN </p>
                    </Link>
                    <Link style={{display: "flex",  paddingLeft: "15px",  textDecoration: "none", color: color.pt, disabled: true}}  to="/../pt">
                        <Img fixed={data.brazil.childImageSharp.fixed} />                    
                        <p style={{paddingLeft: "10px"}}> PT </p>
                    </Link>
                </StyledOptions>
        </StyledHeader>
    )
}

export default Header