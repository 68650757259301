import React from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import Header from "../components/Header"
import Hero from "../components/Hero"
import Importance from "../components/Importance"
import Tags from "../components/Tags"
import Help from "../components/Help"
import TagTracker from "../components/TagTracker"

import Raccoon from "../components/Raccoon"

const StyledPage = styled.div`
`

const Page = () => {
    return(
        <StyledPage>
            <SEO/>
            <Header />
            <Hero />
            <Tags />
            <Importance />
            <TagTracker />
            <Help />
            <Raccoon />
        </StyledPage>
    )
}

export default Page