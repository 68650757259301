import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Topics from "./Topics"

const StyledImportance = styled.div`
    padding: 80px 00px 0px;

    @media(max-width: 800px) {
        padding: 80px 0px 0px;
    }
`

const StyledDiv = styled.div`
    margin: 50px 0px 100px;

    @media(max-width: 800px) {
        margin: 50px 0px 30px;
    }
`

const StyledH2 = styled.h2`
    font-size: 37px;
    line-height: 50px;
    text-align: center;

    @media(max-width: 800px) {
        font-size: 35px;
        margin: 0px 50px;
    }
`

const StyledParagraph = styled.p`
    line-height: 25px;
    text-align: center;
    font-size: 17px;
    margin: 0 200px;

    @media(max-width: 800px) {
        text-align: left;
        margin: 0 50px;
    }

    b {
        font-weight: 600;
    }
`

const Importance = () => {
    const intl = useIntl()

    return (
        <div id="why">
            <StyledImportance>
                <StyledH2>
                    {intl.formatMessage({ id: "importance.h2" })}
                </StyledH2>
                <StyledDiv>
                    <StyledParagraph>
                        {intl.formatMessage({ id: "importance.first_paragraph" })}
                        <b style={{fontWeight: "600px"}}>
                            {intl.formatMessage({ id: "importance.importance" })}
                        </b>
                        {intl.formatMessage({ id: "importance.second_paragraph" })}
                        <b style={{fontWeight: "600px"}}>
                            {intl.formatMessage({ id: "importance.usage" })}
                        </b>
                    </StyledParagraph>
                </StyledDiv>
                <Topics />
            </StyledImportance>
        </div>
    )
}

export default Importance