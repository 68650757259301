import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useIntl } from "gatsby-plugin-intl";

import { TAG_TRACKER_URL, ORANGE, DARKORANGE, GRAY, RACCOON_URL } from "../constants";

const StyledHero = styled.div`
  display: flex;
  padding: 100px 100px 0px;
  justify-content: space-evenly;

  @media (max-width: 800px) {
    padding: 0px 50px;
    display: inline-box;
  }
`;

const StyledTextDiv = styled.div`
  margin: 100px 0px 80px;
  width: 800px;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

const StyledH1 = styled.h1`
  font-size: 50px;
  margin-bottom: 25px;
  line-height: 70px;
`;

const StyledParagraph = styled.p`
  line-height: 25px;
  font-size: 17px;
`;

const StyledButtonGroup = styled.div`
  margin-top: 40px;
  text-align: center;
  display: flex;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledImgDiv = styled.div`
  align-self: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  border-radius: 5px;
  padding: 10px 20px;
  border: 0px;
  background-color: #ffffff;
  color: ${GRAY};
  font-size: 22px;
  border: 1px solid ${GRAY};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledA = styled.a`
  border-radius: 5px;
  padding: 10px 20px;
  border: 0px;
  background-color: ${ORANGE};
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  text-decoration: none;

  :hover {
    background-color: ${DARKORANGE};
  }
`;

const StyledLink = styled.div`
  color: orange;
  font-size: 12px;
  font-weight: bold;
  margin: 20px 0px;
  text-decoration: underline;
  margin: 20px 0;
`;

const Hero = () => {
  const intl = useIntl();

  const query = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <StyledHero id="start">
      <StyledTextDiv>
        <StyledH1>{intl.formatMessage({ id: "hero.h1" })}</StyledH1>
        <StyledParagraph>
          <b style={{ fontWeight: "600" }}>
            {intl.formatMessage({ id: "hero.tag_tracker" })}
          </b>
          {intl.formatMessage({ id: "hero.first_paragraph" })}
          {intl.formatMessage({ id: "hero.second_paragraph_begining" })}
          <a style={{ fontWeight: 600, color: "#F9932C" }} href={RACCOON_URL}>
            {intl.formatMessage({ id: "hero.link" })}
          </a>
          {intl.formatMessage({ id: "hero.second_paragraph_end" })}
        </StyledParagraph>
        <StyledButtonGroup>
          <StyledA href={TAG_TRACKER_URL} target="_blank">
            {intl.formatMessage({ id: "hero.install" })}
          </StyledA>
          <StyledButton onClick={() => scrollTo("#tag-tracker")}>
            {intl.formatMessage({ id: "hero.more" })}
          </StyledButton>
        </StyledButtonGroup>
        <StyledLink>
          {intl.formatMessage({ id: "hero.manual_description" })}
        </StyledLink>
      </StyledTextDiv>
      <StyledImgDiv>
        <Img fixed={query.hero.childImageSharp.fixed} />
      </StyledImgDiv>
    </StyledHero>
  );
};

export default Hero;
