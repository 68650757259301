import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

const StyledTags = styled.div`
    padding: 50px 100px 50px;
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 800px) {
        margin: 0 50px;
        padding: 0px;
        border-bottom: groove;
    }
`

const StyledDiv = styled.div`
    display: flex;
`

const StyledTextDiv = styled.div`
    display: block;
    margin: 80px 0px;
    width: 800px;
`

const StyledImgDiv = styled.div`
    align-self: center;
    margin-left: 20px;

    @media (max-width: 800px) {
        display: none;
    }
`

const StyledH2 = styled.h2`
    font-size: 35px;
    margin-bottom: 25px;
    line-height: 45px;

    @media (max-width: 800px) {
        margin: 0 0 20px;
    }
`

const StyledParagraph = styled.p`
    line-height: 25px;
    text-align: right;
    margin-left: 80px;
    font-size: 17px;

    @media (max-width: 800px) {
        text-align: left;
        margin-left: 0;
    }

    b {
        font-weight: 600;
    }
`

const Tags = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
	    query {
	      tab: file(relativePath: { eq: "midias-logo.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }
	    }
	`)

    return (
        <div>
            <StyledTags id="what">
                <StyledTextDiv>
                    <StyledH2>
                        {intl.formatMessage({ id: "tags.h2" })}
                    </StyledH2>
                    <StyledDiv>
                        <StyledParagraph>
                            <b style={{fontWeight: "600px"}}>
                                {intl.formatMessage({ id: "tags.tag" })}
                            </b>
                                {intl.formatMessage({ id: "tags.paragraph" })}
                        </StyledParagraph>
                    </StyledDiv>
                </StyledTextDiv>
                <StyledImgDiv>
                    <Img fixed={data.tab.childImageSharp.fixed} alt="Google Ads, Google Analytics e Facebook Ads" />
                </StyledImgDiv>
            </StyledTags>
        </div>
    )
}

export default Tags
