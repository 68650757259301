import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import { ORANGE, GRAY } from "../constants"

const StyledHelp = styled.div`
    padding: 100px 150px 50px;

    @media (max-width: 800px) {
        padding: 30px 50px 30px;
    }
`

const StyledButton = styled.div`
    color: ${GRAY};
    cursor: pointer;
    padding: 18px;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-bottom: 1px solid ${ORANGE};
`

const StyledH2 = styled.h2`
    font-size: 37px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 50px;

    @media(max-width: 800px) {
        font-size: 35px;
        margin: 50px 50px;
    }
`

const StyledContent = styled.div`
    line-height: 30px;
    padding: 30px 50px;
    display: ${props => props.show ? 'block' : 'none'};
    overflow: hidden;
`

const StyledResult = styled.div`
    font-size: 20px;
    display: flex;
    justify-content: space-between;
`

const Help = () => {
    const intl = useIntl()

    const query = useStaticQuery(graphql`
	    query {
	      plus: file(relativePath: { eq: "plus.png" }) {
	        childImageSharp {
	          fixed (width: 15) {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      },
	      minus: file(relativePath: { eq: "minus.png" }) {
	        childImageSharp {
	          fixed (width: 10) {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }
	    }
	`)

    const [display, setDisplay] = useState({"1": false, "2": false, "3": false, "4": false, "5": false, "6": false, "7": false})

    const displayContent = (id) => {
        setDisplay({...display, [id]: !display[id]})
    }

    const createList = () => {
        let list = []
        for(let id=0; id<7; id++) {
            list.push(
                <div key={id}>
                    <StyledButton onClick={()=> displayContent(id)}>
                        <StyledResult>
                        <FormattedMessage id={`help.list.${id}.situation`}/>
                            {display[id] ?
                            <Img fixed={query.minus.childImageSharp.fixed} />
                            : <Img fixed={query.plus.childImageSharp.fixed} />
                            }
                        </StyledResult>
                    </StyledButton>
                    <StyledContent show={display[id]}>
                    <FormattedMessage 
                        id={`help.list.${id}.solution`}
                        values={{
                            ul: (...msg) => (
                                <ul style={{listStyleType: "square"}}>{msg}</ul>
                            ),
                            ol: (...msg) => (
                                <ol style={{listStyleType: "decimal"}}>{msg}</ol>
                            ),
                            li: (...msg) => (
                                <li id={id}>{msg}</li>
                            )
                        }}
                    />
                    </StyledContent>
                </div>
            )
        }

        return list
    }

    return(
        <StyledHelp id="help">
            <StyledH2> 
                    {intl.formatMessage({ id: "help.h2" })}
            </StyledH2>
            {createList()}
        </StyledHelp>
    )
}

export default Help