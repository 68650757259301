import React, { useState }  from "react"
import styled from "styled-components"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import { ORANGE, GRAY } from "../../constants"

import videoConsole from "./console.mp4"
import videoModal from "./modal.mp4"
import videoReport from "./report.mp4"

const StyledTab = styled.div`
    margin: 100px 0;

    @media (max-width: 1200px) {
        margin: 100px 0 50px;
    }
`

const StyledOptions = styled.div`
    border-bottom: 1px solid orange;
    margin-bottom: 50px;
`

const StyledOption = styled.button`
    outline: 0;
    padding: 10px 20px;
    border: 0px;
    background-color: ${props => props.selected ? ORANGE : "#ffffff"};
    color: ${props => props.selected ? "#ffffff" : GRAY};;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;

    :first-child {
        margin-left: 0px;
    }

    @media (max-width: 800px) {
        font-size: 14px;
    }
`

const StyledItem = styled.div`
    margin: 0 50px;
    align-self: center;
    max-width: 400px;
`

const StyledSection = styled.div`
    display: ${props => props.selected ? "flex" : "none"};
    justify-content: space-between;


    @media (max-width: 1280px) {
        display: ${props => props.selected ? "inherit" : "none"};
        margin: 0px;
        padding: 0px;
    }

    ul {
        list-style-type: square;
    }

    li {
        line-height: 20px;
        margin-bottom: 20px;
    }
`

const StyledImgDiv = styled.div`
    width: 50%;
    @media (max-width: 1280px) {
        display: none;
    }
`

const StyledH3 = styled.h3`
    padding-bottom: 30px;
    font-weight: 600;
    line-height: 25px;
`

const Tab = () => {
    const intl = useIntl()

    const [select, setSelect] = useState({"modal": true, "console": false, "report": false})

    return (
        <StyledTab>
            <StyledOptions>
                <StyledOption selected={select.modal} onClick={()=> setSelect({"modal": true, "console": false, "report": false})}>
                    {intl.formatMessage({ id: "tag_tracker.funcionality.modal.tab" })}
                </StyledOption>
                <StyledOption selected={select.console} onClick={()=> setSelect({"modal": false, "console": true, "report": false})}>
                    {intl.formatMessage({ id: "tag_tracker.funcionality.console.tab" })}
                </StyledOption>
                <StyledOption selected={select.report} onClick={()=> setSelect({"modal": false, "console": false, "report": true})}>
                    {intl.formatMessage({ id: "tag_tracker.funcionality.report.tab" })}
                </StyledOption>
            </StyledOptions>
            <StyledSection selected={select.modal}>
                <StyledImgDiv>
                    <video style={{maxWidth: "100%"}} autoPlay muted loop controls>
                        <source src={videoModal} type="video/mp4" />
                    </video>
                </StyledImgDiv>
                <StyledItem >
                    <StyledH3> {intl.formatMessage({ id: "tag_tracker.funcionality.modal.h3" })} </StyledH3>
                    <FormattedMessage id="tag_tracker.funcionality.modal.list"
                        values={{
                            ul: (...msg) => (
                                <ul>
                                    {msg}
                                </ul>
                            ),
                            li: (...msg)=> (
                                <li>
                                    {msg}
                                </li>
                            )
                        }}
                    />
                </StyledItem>
            </StyledSection>
            <StyledSection selected={select.console}>
                <StyledImgDiv>
                    <video style={{maxWidth: "100%"}} autoPlay muted loop controls>
                        <source src={videoConsole} type="video/mp4" />
                    </video>
                </StyledImgDiv>
                <StyledItem>
                <StyledH3> {intl.formatMessage({ id: "tag_tracker.funcionality.console.h3" })}  </StyledH3>
                    <FormattedMessage id="tag_tracker.funcionality.console.list"
                        values={{
                            ul: (...msg) => (
                                <ul>
                                    {msg}
                                </ul>
                            ),
                            li: (...msg)=> (
                                <li>
                                    {msg}
                                </li>
                            )
                        }}
                    />
                </StyledItem>
            </StyledSection>
            <StyledSection selected={select.report}>
                <StyledImgDiv>
                    <video style={{maxWidth: "100%"}} autoPlay muted loop controls>
                        <source src={videoReport} type="video/mp4" />
                    </video>
                </StyledImgDiv>
                <StyledItem>
                    <StyledH3> {intl.formatMessage({ id: "tag_tracker.funcionality.report.h3" })} </StyledH3>
                </StyledItem>
            </StyledSection>
        </StyledTab>
    )
}

export default Tab